var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "index-main",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "section-box"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "header-main warp"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.isMobile ? 'new/wap/h5-' : ''}logo.png`)
    }
  })]), _c('div', {
    staticClass: "socials"
  }, [_c('div', {
    staticClass: "btn"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('telegram');
      }
    }
  })], 1), _c('div', {
    staticClass: "btn"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-x"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('x');
      }
    }
  })], 1), _c('div', {
    staticClass: "btn"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-youtube"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('youtube');
      }
    }
  })], 1)])])]), _c('div', {
    staticClass: "section1"
  }, [_c('div', {
    staticClass: "section1-main warp"
  }, [_c('div', {
    staticClass: "section1-left"
  }, [_c('div', {
    staticClass: "section1__label",
    class: !_vm.isMobile && 'animate__animated animate__fadeInLeft'
  }, [_c('p', {
    class: _vm.isMobile && 'wow animate__animated animate__fadeInLeft'
  }, [_vm._v("THE LEADING")]), _c('p', {
    staticClass: "lin",
    class: _vm.isMobile && 'animate__animated animate__fadeInRight'
  }, [_vm._v("WEB3 MOBILE GAME"), _c('br'), _vm._v("ECOSYSTEM")])]), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn-start",
    class: 'wow animate__animated animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.5s"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('game');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Star Game")))]), _c('div', {
    staticClass: "btn-more",
    class: 'wow animate__animated animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.7s"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('telegram');
      }
    }
  }, [_vm._v("Lean More "), _c('svg-icon', {
    attrs: {
      "name": "icon-arrow1"
    }
  })], 1)])]), _c('div', {
    staticClass: "section1-right"
  }, [_c('div', {
    staticClass: "section1-right-main"
  }, [_c('div', {
    staticClass: "section1-img1",
    class: 'wow animate__animated animate__fadeInLeft',
    attrs: {
      "data-wow-duration": "0.8s"
    }
  }, [_c('div', {
    staticClass: "section1-img1-main"
  })]), _c('div', {
    staticClass: "section1-img2",
    class: 'wow animate__animated animate__fadeInRight',
    attrs: {
      "data-wow-duration": "0.9s"
    }
  }, [_c('div', {
    staticClass: "section1-img2-main"
  })])])])])]), _c('div', {
    staticClass: "section2"
  }, [_c('div', {
    staticClass: "section2-main warp"
  }, [_c('div', {
    staticClass: "section2__title"
  }, [_vm._v(_vm._s(_vm.$lang("TapCoins")))]), _c('div', {
    staticClass: "section2__sub",
    class: 'wow animate__animated animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_vm._v(" TapCoins is an innovative platform built on the TON network, designed to transform "), !_vm.isMobile ? _c('br') : _vm._e(), _vm._v(" how users interact with Web3 knowledge and games. We help users explore, "), !_vm.isMobile ? _c('br') : _vm._e(), _vm._v(" learn, and earn through gamified social interactions while providing developers"), !_vm.isMobile ? _c('br') : _vm._e(), _vm._v(" with a powerful platform to publish and monetize their games in the Web3 ecosystem."), !_vm.isMobile ? _c('br') : _vm._e()]), _c('div', {
    staticClass: "section2-list"
  }, [_c('div', {
    staticClass: "section2-item",
    class: 'wow animate__animated animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.7s"
    }
  }, [_vm._m(0)]), _c('div', {
    staticClass: "section2-item",
    class: 'wow animate__animated animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.9s"
    }
  }, [_vm._m(1)]), _c('div', {
    staticClass: "section2-item",
    class: 'wow animate__animated animate__fadeInUp',
    attrs: {
      "data-wow-duration": "1.1s"
    }
  }, [_vm._m(2)])])])]), _c('div', {
    staticClass: "section3"
  }, [_c('div', {
    staticClass: "section3-main warp"
  }, [_c('div', {
    staticClass: "section3__title"
  }, [_vm._v(_vm._s(_vm.$lang("TapCoins Eco-System")))]), _c('div', {
    staticClass: "section3-list"
  }, [_c('div', {
    staticClass: "section3-item"
  }, [_c('span', [_c('div', {
    staticClass: "section3-item__title"
  }, [_vm._v(_vm._s(_vm.$lang("TapCoins Game Center")))]), _c('div', {
    staticClass: "section3-item__sub"
  }, [_vm._v(_vm._s(_vm.$lang("Distribution & Marketplace platform")))])]), _c('div', {
    staticClass: "section3-item-icon"
  }, [_c('div', {
    staticClass: "section-item-icon-main wow animate__animated animate__zoomIn",
    style: {
      background: `url(${require(`@images/new/section3-icon1.png`)}) center/auto 100% no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  })])]), _c('div', {
    staticClass: "section3-item"
  }, [_c('div', {
    staticClass: "section3-item-icon"
  }, [_c('div', {
    staticClass: "section-item-icon-main wow animate__animated animate__zoomIn",
    style: {
      background: `url(${require(`@images/new/section3-icon2.png`)}) center/auto 100% no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  })]), _c('span', [_c('div', {
    staticClass: "section3-item__title"
  }, [_vm._v(_vm._s(_vm.$lang("TapCoins SDK")))]), _c('div', {
    staticClass: "section3-item__sub"
  }, [_vm._v(" Marketing & Tracking"), !_vm.isMobile ? _c('br') : _vm._e(), _vm._v(" Api for Builders ")])])]), _c('div', {
    staticClass: "section3-item"
  }, [_c('span', [_c('div', {
    staticClass: "section3-item__title"
  }, [_vm._v(_vm._s(_vm.$lang("TapCoins Player ID")))]), _c('div', {
    staticClass: "section3-item__sub"
  }, [_vm._v("The Unique Decentralized"), !_vm.isMobile ? _c('br') : _vm._e(), _vm._v("ID System")])]), _c('div', {
    staticClass: "section3-item-icon"
  }, [_c('div', {
    staticClass: "section-item-icon-main wow animate__animated animate__zoomIn",
    style: {
      background: `url(${require(`@images/new/section3-icon3.png`)}) center/auto 100% no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  })])]), _c('div', {
    staticClass: "section3-item"
  }, [_c('div', {
    staticClass: "section3-item-icon"
  }, [_c('div', {
    staticClass: "section-item-icon-main wow animate__animated animate__zoomIn",
    style: {
      background: `url(${require(`@images/new/section3-icon4.png`)}) center/auto 100% no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  })]), _c('span', [_c('div', {
    staticClass: "section3-item__title"
  }, [_vm._v(_vm._s(_vm.$lang("TapCoins DAO")))]), _c('div', {
    staticClass: "section3-item__sub"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Staking & Earning Protocol")) + " ")])])])])])])]), _c('div', {
    staticClass: "section4"
  }, [_c('div', {
    staticClass: "section4-main warp"
  }, [_c('div', {
    staticClass: "section4__title"
  }, [_vm._v(_vm._s(_vm.$lang("TapCoins Game Center")))]), _c('div', {
    staticClass: "section4__sub"
  }, [_vm._v(" A comprehensive distribution and"), _vm.isMobile ? _c('br') : _vm._e(), _vm._v(" marketplace platform for Web3 games ")]), _c('div', {
    staticClass: "section4-list"
  }, [_c('div', {
    staticClass: "section4-item wow animate__animated",
    class: !_vm.isMobile ? 'animate__fadeInUp' : 'animate__fadeInLeft',
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "section4-item-con",
    style: {
      background: `url(${require(`@images/new/${!_vm.isMobile ? 'section4-item1-bg' : 'wap/section4-item1-bg'}.png`)}) center/auto 100% no-repeat`
    }
  }, [_c('div', {
    staticClass: "section4-item-main"
  }, [_c('div', {
    staticClass: "section4-item__title"
  }, [_vm._v(_vm._s(_vm.$lang("Game Market")))]), _c('div', {
    staticClass: "section4-item__label"
  }, [_vm._v(" " + _vm._s(_vm.$lang("A marketplace for discovering, downloading, and playing Web3 games. ")) + " ")])])])]), _c('div', {
    staticClass: "section4-item wow animate__animated",
    class: !_vm.isMobile ? 'animate__fadeInUp' : 'animate__fadeInRight',
    attrs: {
      "data-wow-duration": "0.7s"
    }
  }, [_c('div', {
    staticClass: "section4-item-con",
    style: {
      background: `url(${require(`@images/new/${!_vm.isMobile ? 'section4-item2-bg' : 'wap/section4-item2-bg'}.png`)}) center/auto 100% no-repeat`
    }
  }, [_c('div', {
    staticClass: "section4-item-main"
  }, [_c('div', {
    staticClass: "section4-item__title"
  }, [_vm._v("Game Token"), !_vm.isMobile ? _c('br') : _vm._e(), _vm._v("LaunchPad")]), _c('div', {
    staticClass: "section4-item__label"
  }, [_vm._v(" " + _vm._s(_vm.$lang("A platform for launching game-specific tokens, enabling developers to fundraise and engage users. ")) + " ")])])])]), _c('div', {
    staticClass: "section4-item wow animate__animated",
    class: !_vm.isMobile ? 'animate__fadeInUp' : 'animate__fadeInLeft',
    attrs: {
      "data-wow-duration": "0.9s"
    }
  }, [_c('div', {
    staticClass: "section4-item-con",
    style: {
      background: `url(${require(`@images/new/${!_vm.isMobile ? 'section4-item3-bg' : 'wap/section4-item3-bg'}.png`)}) center/auto 100% no-repeat`
    }
  }, [_c('div', {
    staticClass: "section4-item-main"
  }, [_c('div', {
    staticClass: "section4-item__title"
  }, [_vm._v("NFT Minting")]), _c('div', {
    staticClass: "section4-item__label"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Tools for developers to create and distribute in-game assets as NFTs. ")) + " ")])])])]), _c('div', {
    staticClass: "section4-item wow animate__animated",
    class: !_vm.isMobile ? 'animate__fadeInUp' : 'animate__fadeInRight',
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('div', {
    staticClass: "section4-item-con",
    style: {
      background: `url(${require(`@images/new/${!_vm.isMobile ? 'section4-item4-bg' : 'wap/section4-item4-bg'}.png`)}) center/auto 100% no-repeat`
    }
  }, [_c('div', {
    staticClass: "section4-item-main"
  }, [_c('div', {
    staticClass: "section4-item__title"
  }, [_vm._v("TON Game"), !_vm.isMobile ? _c('br') : _vm._e(), _vm._v("Guild Center")]), _c('div', {
    staticClass: "section4-item__label"
  }, [_vm._v(" " + _vm._s(_vm.$lang("A community hub for game guilds, fostering collaboration and Competition")) + " ")])])])])])])]), _c('div', {
    staticClass: "section5"
  }, [_c('div', {
    staticClass: "section5-main warp"
  }, [_c('div', {
    staticClass: "section5-con"
  }, [_c('div', {
    staticClass: "section5-left"
  }, [_c('div', {
    staticClass: "section5__title"
  }, [_vm._v("TapCoins Builder SDK")]), _c('div', {
    staticClass: "section5__sub"
  }, [_vm._v("An easy-to-use marketing and tracking API")]), _c('div', {
    staticClass: "section5-list"
  }, [_c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_vm._v("Simplifies the integration of TON Network features into games.")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.6s"
    }
  }, [_vm._v("Enables developers to tap into TapCoins' large user base and ecosystem, facilitating growth and engagement.")])])]), _c('div', {
    staticClass: "section5-right wow animate__animated animate__zoomIn",
    style: {
      background: `url(${require(`@images/new/section5-item-img1.png`)}) center/auto 100% no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  })]), _c('div', {
    staticClass: "section5-con"
  }, [_c('div', {
    staticClass: "section5-left"
  }, [_c('div', {
    staticClass: "section5__title"
  }, [_vm._v("TapCoins Player ID")]), _c('div', {
    staticClass: "section5__sub"
  }, [_vm._v("A decentralized ID system built on the TON network")]), _c('div', {
    staticClass: "section5-list"
  }, [_c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.7s"
    }
  }, [_vm._v("Allows users to create a unified ID to track progress, earn rewards, and access various games and tasks seamlessly.")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.8s"
    }
  }, [_vm._v("Enhances user retention and engagement by providing a consistent identity across all games and activities on the platform.")])]), _c('div', {
    staticClass: "section5-other"
  }, [_c('div', {
    staticClass: "section5-other__title"
  }, [_vm._v("The Benefit of get TapCoins Player ID")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.9s"
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  }), _vm._v(" Free AirDrop (Token / NFT) from hundreds of Web3 Game projects. ")], 1), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  }), _vm._v(" Unique account growth system. ")], 1), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "1.1s"
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  }), _vm._v("Build the credit from using Tapcoins Player ID.")], 1), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "1.2s"
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  }), _vm._v("In the future, it can be directly used to log in to Web3 Game and get incentives.")], 1)])]), _c('div', {
    staticClass: "section5-right wow animate__animated animate__zoomIn",
    style: {
      background: `url(${require(`@images/new/section5-item-img2.png`)}) center/auto 100% no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  })]), _c('div', {
    staticClass: "section5-con"
  }, [_c('div', {
    staticClass: "section5-left"
  }, [_c('div', {
    staticClass: "section5__title"
  }, [_vm._v("TapCoins Builder SDK")]), _c('div', {
    staticClass: "section5__sub"
  }, [_vm._v("A staking and earning protocol")]), _c('div', {
    staticClass: "section5__info"
  }, [_vm._v("Users can stake tokens from various games to earn rewards. By staking TAP tokens through TapCoins DAO, you will have the opportunity to earn TAP, limited edition NFTs, and contribute to the development of the TapCoins ecosystem.")]), _c('div', {
    staticClass: "section5-other"
  }, [_c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "1.3s"
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  }), _vm._v("Allows for community governance, enabling users to participate in decision-making processes related to the platform.")], 1), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "1.4s"
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  }), _vm._v("Encourages community involvement and investment in the ecosystem.")], 1)])]), _c('div', {
    staticClass: "section5-right wow animate__animated animate__zoomIn",
    style: {
      background: `url(${require(`@images/new/section5-item-img3.png`)}) center/auto 100% no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  })])])]), _c('div', {
    staticClass: "section6"
  }, [_c('div', {
    staticClass: "section6__title"
  }, [_vm._v("TAPCOINS ROADMAP")]), _vm._m(3), _c('div', {
    staticClass: "section6-main warp"
  }, [_c('div', {
    staticClass: "section6-con"
  }, [_c('div', {
    staticClass: "section6-item"
  }, [_c('div', {
    staticClass: "section6-ad",
    style: {
      background: `url(${require(`@images/new/code1-ad.png`)}) center/auto 100% no-repeat`
    }
  }), _vm._m(4), _c('div', {
    staticClass: "section6-item-main"
  }, [_c('div', {
    staticClass: "section6-item__title wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInRight' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_vm._v("Introduction to Web3 Gaming")]), _c('div', {
    staticClass: "section6-item__label"
  }, [_c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInRight' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.6s"
    }
  }, [_vm._v("Launch of TON Miniapp")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInRight' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.7s"
    }
  }, [_vm._v("Commitment to Web3 knowledge learning")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInRight' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.8s"
    }
  }, [_vm._v("Introduction of the Tap to Earn model")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInRight' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.9s"
    }
  }, [_vm._v("Open invitation reward mechanism")])])])]), _c('div', {
    staticClass: "section6-item"
  }, [_c('div', {
    staticClass: "section6-ad",
    style: {
      background: `url(${require(`@images/new/code2-ad.png`)}) center/auto 100% no-repeat`
    }
  }), _vm._m(5), _c('div', {
    staticClass: "section6-item-main"
  }, [_c('div', {
    staticClass: "section6-item__title wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_vm._v("Immersive Learning Experience")]), _c('div', {
    staticClass: "section6-item__label"
  }, [_c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.6s"
    }
  }, [_vm._v("Launch of Web3 knowledge mining feature")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.7s"
    }
  }, [_vm._v("Introduction of daily bounty activities")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.8s"
    }
  }, [_vm._v("Expansion of the knowledge base")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.9s"
    }
  }, [_vm._v("Regular organization of community events")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_vm._v("Open task achievement system")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "1.1s"
    }
  }, [_vm._v("Integration of YouTube for Web3 video education")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "1.2s"
    }
  }, [_vm._v("Initiation of video code interactive gameplay")])])])]), _c('div', {
    staticClass: "section6-item"
  }, [_c('div', {
    staticClass: "section6-ad",
    style: {
      background: `url(${require(`@images/new/code3-ad.png`)}) center/auto 100% no-repeat`
    }
  }), _vm._m(6), _c('div', {
    staticClass: "section6-item-main"
  }, [_c('div', {
    staticClass: "section6-item__title wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInRight' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_vm._v(" Ecosystem Co-Creation "), _vm.isMobile ? _c('br') : _vm._e(), _vm._v(" and Collaboration ")]), _c('div', {
    staticClass: "section6-item__label"
  }, [_c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInRight' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.6s"
    }
  }, [_vm._v("Launch of task airdrop (USDT/TON) activities")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInRight' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.7s"
    }
  }, [_vm._v("Enrichment of in-game application scenarios")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInRight' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.8s"
    }
  }, [_vm._v("Completion of the first round of $TAP token airdrop")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInRight' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.9s"
    }
  }, [_vm._v("Collaboration with more applications and ecosystems to expand scale")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInRight' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_vm._v("Launch of the TapCoins application platform, integrating third-party applications")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInRight' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "1.1s"
    }
  }, [_vm._v("Establishment of the TapCoins Game Center to provide distribution and marketplace platform for Web3 games")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInRight' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "1.2s"
    }
  }, [_vm._v("Launch of TapCoins SDK to simplify integration for developers")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInRight' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "1.3s"
    }
  }, [_vm._v("Launch of TapCoins Player ID for decentralized user identity management")])])])]), _c('div', {
    staticClass: "section6-item"
  }, [_c('div', {
    staticClass: "section6-ad",
    style: {
      background: `url(${require(`@images/new/code4-ad.png`)}) center/auto 100% no-repeat`
    }
  }), _vm._m(7), _c('div', {
    staticClass: "section6-item-main"
  }, [_c('div', {
    staticClass: "section6-item__title wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInRight' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_vm._v(" Knowledge Application and"), _vm.isMobile ? _c('br') : _vm._e(), _vm._v(" Value Enhancement ")]), _c('div', {
    staticClass: "section6-item__label"
  }, [_c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.6s"
    }
  }, [_vm._v("Multiple rounds of $TAP token airdrops")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.7s"
    }
  }, [_vm._v("$TAP listed on exchanges and issued")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.8s"
    }
  }, [_vm._v("$TAP listed on more mainstream exchanges")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "0.9s"
    }
  }, [_vm._v("Launch of TapCoins DAO to encourage community governance and participation")]), _c('p', {
    staticClass: "wow animate__animated",
    class: _vm.isMobile ? 'animate__fadeInLeft' : 'animate__fadeInUp',
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_vm._v("Provision of staking and earning protocols to incentivize users to earn rewards through staking tokens")])])])])])])]), _c('div', {
    staticClass: "footer"
  }, [_vm._m(8), _c('div', {
    staticClass: "sub-tit"
  }, [_vm._v("Explore Web3, Earn Rewards!")]), _c('div', {
    staticClass: "socials"
  }, [_c('div', {
    staticClass: "btn"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('telegram');
      }
    }
  })], 1), _c('div', {
    staticClass: "btn"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-x"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('x');
      }
    }
  })], 1), _c('div', {
    staticClass: "btn"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-youtube"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('youtube');
      }
    }
  })], 1)])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "section2-item-main"
  }, [_c('div', {
    staticClass: "section2-item__title"
  }, [_c('span', [_vm._v("Players")])]), _c('div', {
    staticClass: "section2-item__label"
  }, [_vm._v("15 MILLION +")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "section2-item-main"
  }, [_c('div', {
    staticClass: "section2-item__title"
  }, [_vm._v("DAU")]), _c('div', {
    staticClass: "section2-item__label"
  }, [_vm._v("2 MILLION +")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "section2-item-main"
  }, [_c('div', {
    staticClass: "section2-item__title"
  }, [_vm._v(" COUNTRIES"), _c('br'), _vm._v(" COVERED ")]), _c('div', {
    staticClass: "section2-item__label"
  }, [_vm._v("200 +")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "section6-line"
  }, [_c('span')]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "section6-code"
  }, [_c('span', [_vm._v("1")]), _vm._v("STEP")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "section6-code"
  }, [_c('span', [_vm._v("2")]), _vm._v("STEP")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "section6-code"
  }, [_c('span', [_vm._v("3")]), _vm._v("STEP")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "section6-code"
  }, [_c('span', [_vm._v("4")]), _vm._v("STEP")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "tit"
  }, [_vm._v("Join "), _c('span', [_vm._v("TapCoins")])]);
}];
export { render, staticRenderFns };