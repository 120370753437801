import "animate.css";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import { WOW } from "wowjs";
export const indexMinxin = {
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
  },
  data() {
    return {
      isLock: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        live: true,
        mobile: true,
      });
      wow.init();
    });
  },
  methods: {
    onSkip(t) {
      switch (t) {
        case "telegram":
          window.open("https://t.me/theTapCoins");
          break;
        case "x":
          window.open("https://x.com/theTapCoins");
          break;
        case "youtube":
          window.open("https://www.youtube.com/@theTapCoins");
          break;
        case "game":
          window.open("https://t.me/TapCoinsBot/app");
          break;
      }
    },
  },
};
